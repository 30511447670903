$(document).ready(function (e) {
    // Preload all images
    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.preloadAfterLoad = true;

    $('.ajax-add-to-wishlist').on('click', function (e) {
        e.preventDefault();
        var spinner_icon = $(this).find('.fa-spinner');
        spinner_icon.show();
        var wishlsit_icon = $(this).find('.fa-heart');
        wishlsit_icon.hide();
        var success_icon = $(this).find('.fa-check');
        success_icon.hide();
        var add_to_wishlist_url = $(this).data('add-to-wishlist');

        $.post(add_to_wishlist_url, {}, function () {
            $.notify({
                // options
                title: 'Prodotto aggiunto alla wishlist',
                message: '<a href="/wishlist/">Visualizza la wishlist></a>'
            }, {
                type: 'minimalist',
                offset: {
                    x: 20,
                    y: 100
                },
                delay: 5000,
                template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-success" role="alert">' +
                    '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                    '<p data-notify="title">{1}</p>' +
                    '<p data-notify="message">{2}</p>' +
                    '</div>'
            });
            spinner_icon.hide();
            success_icon.show();
        }).fail(function (data) {
            $.notify({
                message: 'Non è stato possibile aggiungere l\'ebook alla wishlist.'
            }, {
                // settings
                type: 'danger',
                offset: {
                    x: 20,
                    y: 100
                }
            });

            wishlsit_icon.show();
        }).always(function () {
            spinner_icon.hide();
        });
        return false;
    });

    let $checkoutLine = $(".checkout-preview__line");

    $checkoutLine.each(function() {
        let $quantityInput = $(this).find("#id_quantity");
        let checkoutFormUrl = $(this)
            .find(".form-checkout")
            .attr("action");
        let $qunatityError = $(this).find(
            '.checkout-preview__line__quantity-error'
        );
        let $originalQty = $quantityInput.val();
        let $deleteIcon = $(this).find(".checkout-preview-item-delete");

        $(this).on("change", $quantityInput, function(e) {
            $(".checkout-preview__submit").attr("disabled", "disabled");
            $(".checkout-preview__submit").html("Aggiorno carrello..");
            let newQuantity = $quantityInput.val();
            $.ajax({
                url: checkoutFormUrl,
                method: "POST",
                data: {quantity: newQuantity},
                success: function(response) {
                    location.reload();
                },
                error: function(response) {
                    $qunatityError.html($.parseJSON(response.responseText).error.quantity);
                    $quantityInput.val($originalQty);
                    $(".checkout-preview__submit").removeAttr("disabled");
                    $(".checkout-preview__submit").html("Vai alla cassa");
                }
            });
        });

        $deleteIcon.on('click', function(e) {
            e.preventDefault();
            $.ajax({
                url: checkoutFormUrl,
                method: 'POST',
                data: {quantity: 0},
                success: function(response) {
                    location.reload();
                },
                error: function (response) {
                  console.log($.parseJSON(response.responseText).error.quantity)
                }
            });
        });
    });

    $('#mobile_menu').mmenu({
        navbar 		: false,
        extensions: [
            'pagedim-black'
        ]
    });

    $('.ajax-add-to-checkout').on('click', function (e) {
        e.preventDefault();
        var spinner_icon = $(this).find('.fa-spinner');
        spinner_icon.show();
        var shipping_icon = $(this).find('.fa-shopping-cart');
        shipping_icon.hide();
        var success_icon = $(this).find('.fa-check');
        success_icon.hide();
        var add_to_checkout_url = $(this).data('add-to-checkout');
        var variant_id = $(this).data('variant-id');
        var cover_url = $(this).data('cover-url');
        var qty = 1;

        $.post(add_to_checkout_url, {
            'variant': variant_id,
            'quantity': qty
        }, function () {
            $.notify({
                // options
                title: 'Prodotto aggiunto al carrello.',
                message: '<a href="/checkout/">Vai al carrello></a>'
            }, {
                type: 'minimalist',
                offset: {
                    x: 20,
                    y: 100
                },
                delay: 5000,
                template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                    '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                    '<span data-notify="title">{1}</span>' +
                    '<span data-notify="message">{2}</span>' +
                '</div>'
            });
            spinner_icon.hide();
            success_icon.show();
        }).fail(function (data) {
            var d = data['responseJSON']['error']['__all__'];
            if (d == null) {
                d = data['responseJSON']['error']['quantity'];
            }
            $.notify({
                message: d
            }, {
                // settings
                type: 'danger',
                offset: {
                    x: 20,
                    y: 100
                }
            });

            shipping_icon.show();
        }).always(function () {
            spinner_icon.hide();
        });
        return false
    })

    // sticky header

    // window.addEventListener("scroll", function() {
	
    //     const header = document.querySelector('header')
    //     const scroll = document.documentElement.scrollTop || document.body.scrollTop
    
    //     if(scroll >= 100) {
    //         header.classList.add('fixed');
    //         if (is_staff === 1) {
    //             header.classList.add('fixed_admin');
    //         }
    //     } else {
    //         header.classList.remove('fixed')
    //         if (is_staff === 1) {
    //             header.classList.remove('fixed_admin');
    //         }
    //     }
   
    // });

    let $addressShow = $('.address_show label');
    let $addressHide = $('.address_hide label');
    let $addressForm = $('.checkout__new-address');
    let $initialValue = $('#address_new_address').prop('checked');
    $addressShow.click((e) => {
      $addressForm.slideDown('slow');
    });
    $addressHide.click((e) => {
      $addressForm.slideUp('slow');
    });
    if ($initialValue) {
      $addressForm.slideDown(0);
    } else {
      $addressForm.slideUp(0);
    }
  
});
